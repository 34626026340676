<template>
	<div class="login-container">

		<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on"
			label-position="left">

			<div class="title-container">
				<h3 class="title">Rolum在线学驾照</h3>
			</div>

			<el-form-item prop="userName">
				<span class="svg-container">
					<i slot="prefix" class="el-input__icon el-icon-user"></i>
				</span>
				<el-input ref="userName" v-model="loginForm.userName" placeholder="用户名" name="userName" type="text"
					tabindex="1" autocomplete="on" />
			</el-form-item>

			<el-form-item prop="realName">
				<span class="svg-container">
					<i slot="prefix" class="el-input__icon el-icon-notebook-2"></i>
				</span>
				<el-input ref="realName" v-model="loginForm.realName" placeholder="姓名" name="realName" type="text"
					tabindex="1" autocomplete="on" />
			</el-form-item>

			<el-form-item prop="telNum">
				<span class="svg-container">
					<i slot="prefix" class="el-input__icon el-icon-phone-outline"></i>
				</span>
				<el-input ref="telNum" v-model="loginForm.telNum" placeholder="手机号" name="telNum" type="tel"
					tabindex="1" autocomplete="on" />
			</el-form-item>


			<el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
				<el-form-item prop="password">
					<span class="svg-container">
						<i slot="prefix" class="el-input__icon el-icon-view"></i>
					</span>
					<el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
						placeholder="密码" name="password" tabindex="2" autocomplete="on" show-password
						@keyup.enter.native="handleLogin" />
				</el-form-item>
			</el-tooltip>
<!-- 
			<el-form-item prop="remark">
				<span class="svg-container">
					<i slot="prefix" class="el-input__icon el-icon-search"></i>
				</span>
				<el-input ref="remark" v-model="loginForm.remark" placeholder="备注" name="remark" type="text"
					tabindex="1" autocomplete="on" />
			</el-form-item>
 -->

			<div style="position:relative;">

				<el-row :gutter="24">

					<el-col :span="16">
						<el-button type="warning" style="width:100%;margin-bottom:30px;" icon="el-icon-user-solid"
							@click.native.prevent="handleReg">注册</el-button>
					</el-col>

					<el-col :span="8">
						<el-button style="width:100%;margin-bottom:30px;" icon="el-icon-s-promotion"
							@click.native.prevent="toLogin">去登录</el-button>
					</el-col>

				</el-row>

			</div>
		</el-form>

	</div>
</template>

<script>
	import {
		userReg
	} from '@/api/zhuce'

	export default {
		name: 'Register',
		data() {
			const validateUsername = (rule, value, callback) => {
				if (value.length < 5) {
					callback(new Error('用户名不能小于5个字符'))
				} else {
					callback()
				}
			}
			const validatePassword = (rule, value, callback) => {
				if (value.length < 5) {
					callback(new Error('密码不能小于5个字符'))
				} else {
					callback()
				}
			}
			
			const validateTel = (rule, value, callback) => {
				if (value.length !== 11) {
					callback(new Error('手机号位数有误'))
				} else {
					callback()
				}
			}

			const validateRealName = (rule, value, callback) => {
				if (value.length < 2) {
					callback(new Error('用户姓名不能为空！'))
				} else {
					callback()
				}
			}

			return {
				loginForm: {
					userName: '',
					realName: '',
					password: '',
					telNum: ''
				},
				loginRules: {
					userName: [{
						required: true,
						trigger: 'blur',
						validator: validateUsername
					}],
					password: [{
						required: true,
						trigger: 'blur',
						validator: validatePassword
					}],
					realName: [{
						required: true,
						trigger: 'blur',
						validator: validateRealName
					}],
					telNum: [{
						required: true,
						trigger: 'blur',
						validator: validateTel
					}],
				},
				passwordType: 'password',
				capsTooltip: false,
				loading: false,
				redirect: undefined,
				otherQuery: {}
			}
		},

		methods: {

			toLogin() {
				this.$router.push({
					name: '登陆'
				})
			},

			showPwd() {
				if (this.passwordType === 'password') {
					this.passwordType = ''
				} else {
					this.passwordType = 'password'
				}
				this.$nextTick(() => {
					this.$refs.password.focus()
				})
			},
			// 注册成功跳转登陆
			handleReg() {
				let that = this
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.loading = true
						userReg(this.loginForm)
							.then(() => {
								// this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
								this.$message.success('注册成功!')
								that.loading = false
								this.$router.push({
									name: '登陆'
								})
							})
							.catch(() => {
								that.loading = false
							})
					} else {
						console.log('提交错误！')
						return false
					}
				})
			},
			// 注册成功跳转主页
			handleReg1() {
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.loading = true
						this.$store.dispatch('user/reg', this.loginForm)
							.then(() => {
								this.$router.push({
									path: this.redirect || '/',
									query: this.otherQuery
								})
								this.loading = false
							})
							.catch(() => {
								this.loading = false
							})
					} else {
						console.log('提交错误！')
						return false
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	$bg:#283443;
	$light_gray:#fff;
	$cursor: #fff;

	@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
		.login-container .el-input input {
			color: $cursor;
		}
	}

	/* reset element-ui css */
	.login-container {
		.el-input {
			display: inline-block;
			height: 47px;
			width: 85%;

			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				color: $light_gray;
				height: 47px;
				caret-color: $cursor;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px $bg inset !important;
					-webkit-text-fill-color: $cursor !important;
				}
			}
		}

		.el-form-item {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			color: #454545;
		}
	}
</style>

<style lang="scss" scoped>
	$bg:#2d3a4b;
	$dark_gray:#889aa4;
	$light_gray:#eee;

	.login-container {
		min-height: 100%;
		width: 100%;
		background-color: $bg;
		overflow: hidden;

		.login-form {
			position: relative;
			width: 520px;
			max-width: 100%;
			padding: 160px 35px 0;
			margin: 0 auto;
			overflow: hidden;
		}

		.tips {
			font-size: 14px;
			color: #fff;
			margin-bottom: 10px;

			span {
				&:first-of-type {
					margin-right: 16px;
				}
			}
		}

		.svg-container {
			padding: 6px 5px 6px 15px;
			color: $dark_gray;
			vertical-align: middle;
			width: 30px;
			display: inline-block;
		}

		.title-container {
			position: relative;

			.title {
				font-size: 26px;
				color: $light_gray;
				margin: 0px auto 40px auto;
				text-align: center;
				font-weight: bold;
			}
		}

		.show-pwd {
			position: absolute;
			right: 10px;
			top: 7px;
			font-size: 16px;
			color: $dark_gray;
			cursor: pointer;
			user-select: none;
		}

		.thirdparty-button {
			position: absolute;
			right: 0;
			bottom: 6px;
		}

		@media only screen and (max-width: 470px) {
			.thirdparty-button {
				display: none;
			}
		}
	}
</style>
